import type { StyledComponent } from "@emotion/styled";
import styled from "@emotion/styled";
import { notEmpty } from "@product/scmp-sdk";
import type { FunctionComponent } from "react";

import type { ExtraProps as YoutubeExtraProps } from "scmp-app/components/schema-render/common/iframe/scmp-youtube";
import { SCMPYoutube } from "scmp-app/components/schema-render/common/iframe/scmp-youtube";
import type { ContentSchemaRenderProps } from "scmp-app/components/schema-render/content";

import { DataWrapperIframe } from "./data-wrapper";
import { GenericIframe } from "./generic-iframe";
import { isDataWrapper, isScmpYoutubeVideo } from "./helpers";

export type Props = {
  "data-video-source"?: string;
  "data-youtube-id"?: string;
  src?: string;
} & ContentSchemaRenderProps &
  YoutubeExtraProps;

const Component: FunctionComponent<Props> = ({ src, ...attribs }) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getIframeComponent = (): null | StyledComponent<any, any> => {
    // TODO: check if it's quiz
    if (!notEmpty(src)) return null;
    if (isScmpYoutubeVideo(attribs)) return SCMPYoutube;
    if (isDataWrapper(src)) return DataWrapperIframe;
    return GenericIframe;
  };

  const Component = getIframeComponent();
  return Component ? <Component {...{ ...attribs, src }} /> : null;
};

Component.displayName = "Iframe";
export const Iframe = styled(Component)``;
